import { useTheme } from '@emotion/react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Input } from '@/components/Input'
import { Textarea } from '@/components/Textarea'
import { FormControl } from '@/components/FormControl'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'

import { Link } from '@/lib/link'

import { schema, FormValue } from './schema'

type Props = {
  onSubmit: (value: FormValue) => Promise<void>
}

export function FormComponent({ onSubmit }: Props) {
  const theme = useTheme()

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: '',
      email: '',
      tel: '',
      companyName: '',
      detail: '',
      acceptedTerm: false,
      agreedToMarketingConsent: false,
    },
    resolver: zodResolver(schema),
  })

  const {
    register,
    handleSubmit,
    reset: resetForm,
    control,
    formState: { isSubmitting, errors },
  } = methods

  const _onSubmit = async (data: FormValue) => {
    if (isSubmitting) return

    await onSubmit(data)
    resetForm()
  }

  return (
    <form
      onSubmit={handleSubmit(_onSubmit)}
      css={theme.mq({
        display: 'grid',
        gridTemplateColumns: {
          base: '1fr',
          sm: 'repeat(2, 1fr)',
        },
        gridGap: '--space-4',
      })}
    >
      <FormControl
        label="ชื่อ-นามสกุล"
        isRequired
        errorText={errors.name?.message}
      >
        <Input
          isInvalid={Boolean(errors.name?.message)}
          sizing="lg"
          placeholder="ชื่อ-นามสกุล"
          variant="filled"
          {...register('name')}
        />
      </FormControl>
      <FormControl
        label="อีเมลติดต่อ"
        isRequired
        errorText={errors.email?.message}
      >
        <Input
          isInvalid={Boolean(errors.email?.message)}
          sizing="lg"
          type="email"
          placeholder="อีเมลติดต่อ"
          variant="filled"
          {...register('email')}
        />
      </FormControl>
      <FormControl
        label="ชื่อบริษัท"
        isRequired
        errorText={errors.companyName?.message}
      >
        <Input
          isInvalid={Boolean(errors.companyName?.message)}
          sizing="lg"
          placeholder="ชื่อบริษัท"
          variant="filled"
          {...register('companyName')}
        />
      </FormControl>
      <FormControl
        label="เบอร์โทรติดต่อ"
        isRequired
        errorText={errors.tel?.message}
      >
        <Input
          isInvalid={Boolean(errors.tel?.message)}
          sizing="lg"
          type="tel"
          placeholder="เบอร์โทรติดต่อ"
          variant="filled"
          {...register('tel')}
        />
      </FormControl>
      <FormControl
        label="เขียนข้อความของคุณ"
        errorText={errors.detail?.message}
        sx={{
          gridColumn: {
            base: '1',
            sm: 'span 2',
          },
        }}
      >
        <Textarea
          isInvalid={Boolean(errors.detail?.message)}
          sizing="lg"
          placeholder="ข้อความของคุณ"
          variant="filled"
          sx={{
            height: '192px',
          }}
          {...register('detail')}
        />
      </FormControl>
      <div
        css={theme.mq({
          color: '--colors-primary-600',
          gridColumn: {
            base: '1',
            sm: 'span 2',
          },
        })}
      >
        <Controller
          control={control}
          name="acceptedTerm"
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox isRequired isChecked={value} onChange={onChange}>
                ฉันยอมรับ{' '}
                <Link
                  to="TERMS_OF_USE"
                  sx={{
                    ...theme.layerStyles.actionable,
                    textDecoration: 'underline',
                  }}
                >
                  เงื่อนไขในการใช้เว็บไซต์
                </Link>{' '}
                และ{' '}
                <Link
                  to="PRIVACY_POLICY"
                  sx={{
                    ...theme.layerStyles.actionable,
                    textDecoration: 'underline',
                  }}
                >
                  นโยบายความเป็นส่วนตัว
                </Link>
              </Checkbox>
            )
          }}
        />
        <Controller
          control={control}
          name="agreedToMarketingConsent"
          render={({ field: { value, onChange } }) => {
            return (
              <Checkbox
                isChecked={value}
                onChange={onChange}
                sx={{ marginTop: '--space-2' }}
              >
                ฉันต้องการรับโปรโมชันและข่าวสารทางการตลาดเกี่ยวกับ Consent Wow
                และบริการอื่น ๆ จากเรา บริษัทในเครือ
                บริษัทย่อยและพันธมิตรทางธุรกิจ &#8203;
                <span style={{ whiteSpace: 'nowrap' }}>
                  (คุณสามารถยกเลิกได้ทุกเมื่อ)
                </span>
              </Checkbox>
            )
          }}
        />
      </div>
      <Button
        sx={{
          gridColumn: {
            base: '1',
            sm: 'span 2',
          },
          fontSize: {
            base: '--fontSizes-md',
            xs: '20px',
          },
          height: {
            base: '--space-10',
            xs: '--space-16',
          },
          marginTop: '--space-2',
        }}
        type="submit"
        loadingText="กำลังส่งข้อมูล..."
        isLoading={isSubmitting}
      >
        ส่งข้อมูล
      </Button>
    </form>
  )
}
